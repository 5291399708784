<template>
   <!-- footer start -->
   <footer class="footer widget-footer bg-base-dark text-base-white clearfix">
      <div class="first-footer">
         <div class="container">
            <div class="row">
               <div class=" col-lg-7 col-md-12 col-sm-12 widget-area">
                  <div class="widget widget_text">
                     <h3 class="widget-title">About our company</h3>
                     <div class="textwidget widget-text">We have been the most trusted “Health care Nutrition” manual
                        for 25 years, and We are proud of our rich history of providing evidence based nutrition
                        resources from the registered dietitian nutritionists services. Our personalized nutrition foods
                        are right for you. Emerged to maintain our reputation.
                     </div>
                     <div class="footer-content-cta">
                        <div class="prt-email">
                           <a href="mailto:dietcareayodhya@gmail.com">dietcareayodhya@gmail.com
                              <i class="ti-arrow-top-right"></i>
                           </a>
                        </div>
                        <div class="prt-call">
                           <a href="tel:+91 896 048 6620">+91 896 048 6620</a>
                        </div>
                     </div>
                     <div class="prt-social-links-wrapper">
                        <ul class="social-icons">
                           <li class="prt-social-facebook">
                              <a target="_blank" href="https://www.facebook.com/12">Facebook</a>
                           </li>
                           <li class="prt-social-instagram">
                              <a target="_blank" href="https://www.instagram.com/12/">Instagram</a>
                           </li>
                           <li class="prt-social-twitter">
                              <a target="_blank" href="https://twitter.com/12">Twitter</a>
                           </li>
                           <li class="prt-social-dribbble">
                              <a target="_blank" href="https://dribbble.com/12">Dribbble</a>
                           </li>
                        </ul>
                     </div>
                  </div>
               </div>
               <div class=" col-lg-5 col-md-12 col-sm-12 widget-area">
                  <div class="row pl-50 res-1199-pl-0">
                     <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                        <div class="footer-widget-box res-767-mt-30">
                           <h3 class="widget-title">Services</h3>
                           <ul class="menu-footer-link">
                              <li><router-link to="/weight-loss-program">Weight loss programs</router-link></li>
                              <li><router-link to="/nutrition-and-lifestyle">Fitness performance</router-link></li>
                              <li><router-link to="/children-nutrition">Child Diet</router-link></li>
                              <li><router-link to="/personalized-nutrition">Diet schedule </router-link></li>

                           </ul>
                        </div>
                     </div>
                     <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                        <div class="footer-widget-box res-767-mt-30">
                           <h3 class="widget-title">Open hour</h3>
                           <ul class="tm-daylist-block">
                              <li> Mon-Fri : <span class="service-day"> 9:00 - 18:00</span></li>
                              <li> Saturday : <span class="service-day">9:00 - 16:00</span></li>
                              <li> Sunday : <span class="service-day">Closed</span></li>
                           </ul>
                        </div>
                     </div>
                  </div>
                  <div class="row pl-50 res-1199-pl-0">
                     <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                        <div class="tm-agency-store">
                           <div class="footer-widget-box res-767-mt-30">
                              <!-- <h3 class="widget-title">Download our mobile app</h3>
                                    <ul class="prt-list footer-payment">
                                       <li><a href="#">
                                          <img class="img-fluid border-rad_5" src="images/google-play.png" alt="image" width="143" height="46"></a>
                                       </li>
                                       <li><a href="#">
                                          <img class="img-fluid border-rad_5" src="images/apple-store.png" alt="image" width="143" height="46"></a>
                                       </li>
                                    </ul> -->
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div class="bottom-footer">
         <div class="container">
            <div class="row">
               <div class="col-lg-12">
                  <div class="copyright">
                     <p class="mb-0">Copyright © 2024 All rights reserved. Powered by <a
                           href="https://cloudexisttechnologies.com/">Cloudexis Technologies.</a></p>
                     <div class="bottom-footer-nav">
                        <ul class="bottom-footer-nav-menu">
                           <li class="bottom-footer-nav-item">
                              <router-link to="/about">About</router-link>
                           </li>
                           <li class="bottom-footer-nav-item">
                              <router-link to="/contact">Contact us</router-link>
                           </li>
                           <li class="bottom-footer-nav-item">
                              <router-link to="/faq">Faq</router-link>

                           </li>
                           <!-- <li class="bottom-footer-nav-item">
                              <a href="#">Events</a>
                           </li> -->
                        </ul>
                     </div>
                  </div>
                  <!--copyright -->
               </div>
            </div>
         </div>
      </div>
   </footer>
   <!-- footer end -->

</template>

<script>
export default {
   name: 'AppFooter'
};
</script>