import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '../views/HomeView.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/blog-details',
    name: 'blog-details',
    component: () => import(/* webpackChunkName: "blog-details" */ '../views/BlogDetailsView.vue')
  },
  {
    path: '/blog',
    name: 'blog',
    component: () => import(/* webpackChunkName: "blog" */ '../views/BlogView.vue')
  },
  {
    path: '/children-nutrition',
    name: 'children-nutrition',
    component: () => import(/* webpackChunkName: "children-nutrition" */ '../views/ChildrenNutritionView.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import(/* webpackChunkName: "contact" */ '../views/ContactView.vue')
  },
  {
    path: '/error',
    name: 'error',
    component: () => import(/* webpackChunkName: "error" */ '../views/ErrorView.vue')
  },
  {
    path: '/faq',
    name: 'faq',
    component: () => import(/* webpackChunkName: "faq" */ '../views/FaqView.vue')
  },
  {
    path: '/nutrition-and-lifestyle',
    name: 'nutrition-and-lifestyle',
    component: () => import(/* webpackChunkName: "nutrition-and-lifestyle" */ '../views/NutritionAndLifestyleView.vue')
  },
  {
    path: '/our-team',
    name: 'our-team',
    component: () => import(/* webpackChunkName: "our-team" */ '../views/OurTeamView.vue')
  },
  {
    path: '/personalized-nutrition',
    name: 'personalized-nutrition',
    component: () => import(/* webpackChunkName: "personalized-nutrition" */ '../views/PersonalizedNutritionView.vue')
  },
  {
    path: '/portfolio',
    name: 'portfolio',
    component: () => import(/* webpackChunkName: "portfolio" */ '../views/PortfolioView.vue')
  },
  {
    path: '/portfolio-details',
    name: 'portfolio-details',
    component: () => import(/* webpackChunkName: "portfolio-details" */ '../views/PortfolioDetailsView.vue')
  },
  {
    path: '/services',
    name: 'services',
    component: () => import(/* webpackChunkName: "services" */ '../views/ServicesView.vue')
  },
  {
    path: '/team-details',
    name: 'team-details',
    component: () => import(/* webpackChunkName: "team-details" */ '../views/TeamDetailsView.vue')
  },
  {
    path: '/weight-loss-program',
    name: 'weight-loss-program',
    component: () => import(/* webpackChunkName: "weight-loss-program" */ '../views/WeightLossProgramView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
