<template>
   <!-- header start -->
   <header id="masthead" class="header prt-header-style-01">
      <!-- topbar -->
      <div class="top_bar prt-topbar-wrapper clearfix">
         <div class="container-fluid">
            <div class="row">
               <div class="col-lg-12">
                  <div class="row">
                     <div class="col-lg-6">
                        <div class="top_bar_contact_item">
                           <span>Welcome to nutricare a nutrition and diet</span>
                        </div>
                     </div>
                     <div class="col-lg-6">
                        <ul class="social-icons">
                           <li class="prt-social-facebook">
                              <a target="_blank" href="#">Facebook</a>
                           </li>
                           <li class="prt-social-instagram">
                              <a target="_blank" href="#">Instagram</a>
                           </li>
                           <li class="prt-social-twitter">
                              <a target="_blank" href="#">Twitter</a>
                           </li>
                        </ul>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <!-- topbar-end -->
      <!-- site-header-menu -->
      <div id="site-header-menu" class="site-header-menu bg-base-white">
         <div class="site-header-menu-inner prt-stickable-header">
            <div class="container-fluid">
               <div class="row">
                  <div class="col-lg-12">
                     <div class="d-xl-flex align-items-center justify-content-between">
                        <div class="">
                           <!-- site-branding -->
                           <div class="site-branding">
                              <h1><router-link to="/" class="home-link" rel="home">
                                    <img id="logo-img" width="190" height="45" class="img-fluid" src="images/logo.png"
                                       alt="logo-img">
                                 </router-link>
                              </h1>
                              <div class="d-flex flex-row m-auto align-items-center">
                                 <div class="menu-link">
                                    <div class="btn-show-menu-mobile menubar menubar--squeeze">
                                       <span class="menubar-box">
                                          <span class="menubar-inner"></span>
                                       </span>
                                    </div>
                                    <!-- menu -->
                                    <nav class="main-menu menu-mobile" id="menu">
                                       <ul class="menu">
                                          <li class="mega-menu-item">
                                             <router-link to="/">Home</router-link>
                                          </li>
                                          <li class="mega-menu-item">
                                             <router-link to="/about">About</router-link>

                                          </li>
                                          <li class="mega-menu-item">
                                             <a href="#" class="mega-menu-link">Services</a>
                                             <ul class="mega-submenu">
                                                <li><router-link to="/weight-loss-program">Weight loss
                                                      programs</router-link></li>
                                                <li><router-link to="/nutrition-and-lifestyle">Nutrition and
                                                      lifestyle</router-link></li>
                                                <li><router-link to="/children-nutrition">Children
                                                      nutrition</router-link></li>
                                                <li><router-link to="/personalized-nutrition">Personalized nutrition
                                                   </router-link></li>
                                             </ul>
                                          </li>
                                          <!-- <li class="mega-menu-item">
                                                   <a href="#" class="mega-menu-link">Pages</a>
                                                   <ul class="mega-submenu">
                                                      
                                                      <li><router-link to="/services">Service</router-link></li>
                                                      <li><router-link to="/our-team">Our Team</router-link></li> 
                                                      <li><router-link to="/team-details">Team Details</router-link></li> 
                                                      <li><router-link to="/faq">Faq</router-link></li>
                                                      <li><router-link to="/error">Error</router-link></li>
                                                   </ul>
                                                </li> -->
                                          <!-- <li class="mega-menu-item">
                                             <router-link to="/services">Service</router-link>
                                          </li> -->
                                          <!-- <li class="mega-menu-item">
                                             <router-link to="/blog">Blog</router-link>
                                          </li> -->
                                          <li class="mega-menu-item">
                                             <router-link to="/faq">Faq</router-link>
                                          </li>
                                          <!-- <li class="mega-menu-item">
                                                   <a href="#" class="mega-menu-link">Portfolio</a>
                                                   <ul class="mega-submenu">
                                                      <li><router-link to="/portfolio">Portfolio</router-link></li>
                                                      <li><router-link to="/portfolio-details">Portfolio Details</router-link></li>
                                                   </ul>
                                                </li> -->
                                          <!-- <li class="mega-menu-item">
                                                   <a href="#" class="mega-menu-link">Blog</a>
                                                   <ul class="mega-submenu">
                                                      <li><router-link to="/blog">Blog</router-link></li>
                                                      <li><router-link to="/blog-details">Blog Details</router-link></li>
                                                   </ul>
                                                </li> -->
                                          <li class="mega-menu-item">

                                             <router-link to="/contact">Contact</router-link>
                                          </li>
                                       </ul>
                                    </nav>
                                    <!-- menu end -->
                                 </div>
                              </div>
                           </div>
                           <!-- site-branding end -->
                        </div>
                        <div class="side-menu-container">
                           <div class="side-menu">
                              <a href="#">
                                 <i class="ti-menu"></i>
                              </a>
                           </div>
                           <div class="side-overlay">
                              <div class="side bg-base-white">
                                 <a href="#" class="close-side">
                                    <i class="icon-close"></i>
                                 </a>
                                 <div class="prt-fbar">
                                    <div class="prt-fbar-inner">
                                       <div
                                          class="prt-fbar-box-w prt-bg prt-bgimage-yes prt-bgcolor-skincolor prt-textcolor-white">
                                          <div class="prt-row-wrapper-bg-layer prt-bg-layer"></div>
                                          <div class="prt-fbar-content-wrapper">
                                             <div class="prt-fbar-content">
                                                <div class="prt-fbar-box">
                                                   <div class="floatingbar-widget-top">
                                                      <div class="prt-fbar-widget prt-fbar-widget-even">
                                                         <div class="textwidget widget-text">
                                                            <div class="prt-author-widget text-center text-base-white">
                                                               <div class="author-widget_img">
                                                                  <img src="images/author-img.jpg" class="author-img"
                                                                     alt="author-img">
                                                               </div>
                                                               <h4 class="author-name">Anjali Singh</h4>
                                                               <p class="author-widget_text">There are many variations
                                                                  of passages of Lorem Ipsum available, but the majority
                                                                  have alteration.</p>
                                                               <span class="author-autograph">
                                                                  <img class="author-img" src="images/author-sign.png"
                                                                     alt="author image">
                                                               </span>
                                                            </div>
                                                         </div>
                                                      </div>
                                                      <div class="prt-fbar-widget prt-fbar-widget-odd">
                                                         <h3 class="widget-title">Opening Hours:</h3>
                                                         <div class="textwidget widget-text">
                                                            These are our normal opening hours. When we are closed can
                                                            be found here.<br>
                                                            <div class="prt-pricelistbox-wrapper ">
                                                               <div class="prt-pricelist-block-wrapper">
                                                                  <ul class="prt-pricelist-block mt-10">
                                                                     <li>
                                                                        <span>Monday - Friday</span>
                                                                        <p class="service-price">8.00 – 17.00</p>
                                                                     </li>
                                                                     <li>
                                                                        <span>Saturday</span>
                                                                        <p class="service-price">8.00 – 15.00</p>
                                                                     </li>
                                                                     <li>
                                                                        <span>Sunday</span>
                                                                        <p class="service-price">Closed</p>
                                                                     </li>
                                                                  </ul>
                                                               </div>
                                                            </div>
                                                         </div>
                                                      </div>
                                                      <div class="prt-fbar-widget prt-fbar-widget-even">
                                                         <div class="featured-icon-box left-icon icon-align-top">
                                                            <div class="featured-icon">
                                                               <div
                                                                  class="prt-icon prt-icon_element-border prt-icon_element-color-white prt-icon_element-size-md prt-icon_element-style-square">
                                                                  <i class="fa fa-phone"></i>
                                                               </div>
                                                            </div>
                                                            <div class="featured-content">
                                                               <div class="featured-title">
                                                                  <h5><a href="tel:+91 896 048 6620">+91 896 048 6620</a></h5>
                                                               </div>
                                                               <div class="featured-desc">
                                                                  <p>Have a question? call us now</p>
                                                               </div>
                                                            </div>
                                                         </div>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <span class="prt-fbar-close">
                                          <span class="prt-fbar-close-icon">
                                             <i class="ti ti-close"></i>
                                          </span>
                                       </span>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <!-- site-header-menu-end -->
   </header>
   <!-- header end -->
</template>
<style scoped>
/* This will style the active link */
.router-link-active {

   color: #9fdd0b;

   text-shadow: none;
}
</style>
<script>
export default {
   name: 'AppHeader'
};
</script>